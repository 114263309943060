import { createApp, type Component } from 'vue'
import * as Sentry from '@sentry/vue'
import { createHead } from '@vueuse/head'
import { type AuthenticationResult, EventType } from '@azure/msal-browser'
import { DefaultApolloClient } from '@vue/apollo-composable'

import router, { CustomNavigationClient } from './router'
import { msalPlugin } from './plugins/msal'
import { msalInstance } from './msalConfig'
import { apolloClient } from './apollo'
import App from './App.vue'
import 'uno.css'
import '@unocss/reset/tailwind.css'
import '@ijru/components/style.css'

export const app = createApp(App as Component)

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_COMMIT_REF?.toString(),
    environment: import.meta.env.VITE_ENV?.toString(),
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.registration\.ijru\.(sport|dev)\//],
    integrations: [Sentry.browserTracingIntegration({ router })],
    // Performance Monitoring
    tracesSampleRate: 1.0 // Capture 100% of the transactions, reduce in production!
  })
}

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router)
msalInstance.setNavigationClient(navigationClient)

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

app
  .provide(DefaultApolloClient, apolloClient)
  .use(createHead())
  .use(router)
  .use(msalPlugin, msalInstance)
  .mount('#app')
